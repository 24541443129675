var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "center-legenda", attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { staticClass: "justify-center" },
        _vm._l(_vm.legendas, function(legend) {
          return _c(
            "v-col",
            {
              key: legend.id,
              staticClass: "justify-align-center",
              attrs: { cols: "2" }
            },
            [
              _c(
                "div",
                { staticClass: "caption" },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mb-1",
                      attrs: { color: legend.color, size: "30" }
                    },
                    [
                      _c("v-icon", { attrs: { color: "black", small: "" } }, [
                        _vm._v(_vm._s(legend.span))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(legend.message) + " ")
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }