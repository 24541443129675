<template>
  <v-card outlined class="center-legenda">
    <v-row class="justify-center">
      <v-col
        cols="2"
        v-for="legend in legendas"
        :key="legend.id"
        class="justify-align-center"
      >
        <div class="caption">
          <v-avatar :color="legend.color" size="30" class="mb-1">
            <v-icon color="black" small>{{ legend.span }}</v-icon>
          </v-avatar>
          {{ legend.message }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { statusInterpreter } from "@/utils/statusInterpreter";

export default {
  name: "legenda",
  data() {
    return {
      legendas: [
        {
          id: 0,
          color: statusInterpreter.cor(0),
          message: "OK",
          span: statusInterpreter.span(0),
        },
        {
          id: 2,
          color: statusInterpreter.cor(2),
          message: "Informação",
          span: statusInterpreter.span(2),
        },
        {
          id: 3,
          color: statusInterpreter.cor(4),
          message: "Atenção",
          span: statusInterpreter.span(4),
        },
        {
          id: 4,
          color: statusInterpreter.cor(6),
          message: "Problema",
          span: statusInterpreter.span(6),
        },
      ],
    };
  },
};
</script>

<style scoped>
.center-legenda {
  margin: auto;
  width: 80%;
}
</style>